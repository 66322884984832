import React from 'react';
import Image from 'next/image';
import { Box, Typography, Grid } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import socureImg from '../../public/companies/socure.png';
import barbadosImg from '../../public/companies/barbados.png';
import daonImg from '../../public/companies/daon.png';
import alivaImg from '../../public/companies/aliva.png';
import youverseImg from '../../public/companies/youverse.png';
import classterImg from '../../public/companies/classter.png';

export default function FeaturesList() {
  const { t } = useTranslation(['auth', 'common']);

  const features = [
    t('auth:feature-list-1'),
    t('auth:feature-list-2'),
    t('auth:feature-list-3'),
    t('auth:feature-list-4'),
  ];

  return (
    <Box
      sx={{
        marginTop: { xs: '30px', md: 0 },
      }}>
      <Typography
        variant="h2"
        sx={{ fontSize: '48px', color: '#FFFFFF', fontWeight: 500, marginBottom: '24px' }}>
        {t('auth:feature-list-title')}
      </Typography>
      <br />

      {features.map((feature, index) => (
        <Grid container key={index} gap="8px" alignItems="center" sx={{ marginBottom: '20px' }}>
          <CheckCircleOutlineIcon color="#B2CCFF" fontSize="medium" sx={{ color: '#B2CCFF' }} />
          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              color: '#B2CCFF',
              fontWeight: 500,
            }}>
            {feature}
          </Typography>
        </Grid>
      ))}

      <br />

      <Typography
        variant="body1"
        sx={{
          display: 'flex',
          color: '#B2CCFF',
          fontWeight: 500,
          marginTop: '24px',
        }}>
        {t('auth:partners-list-title')}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          '& > *': {
            width: '136px',
            height: '64px',
            backgroundBlendMode: 'screen',
          },
        }}>
        <Image src={socureImg} alt="Socure Logo" />
        <Image src={barbadosImg} alt="Barbados Port Logo" />
        <Image src={daonImg} alt="Daon Logo" />
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          '& > *': {
            width: '136px',
            height: '64px',
            backgroundBlendMode: 'screen',
          },
        }}>
        <Image src={alivaImg} alt="Aliva Logo" />
        <Image src={classterImg} alt="Classter Logo" />
        <Image src={youverseImg} alt="Youverse Logo" />
      </Box>
    </Box>
  );
}
